import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import s from "../styles/HomePage.module.css";
import {
  useHapticFeedback,
  useInitData,
  useShowPopup,
} from "@vkruglikov/react-telegram-web-app";
import SupportModal from "../components/Modals/SupportModal";
import TariffPlansModal from "../components/Modals/TariffPlansModal";
import EarnMoneyWithUs from "../components/Modals/EarnMoneyWithUs";
import axios from "axios";
import { Loader } from "../components/Loader";

const HomePage = () => {
  const [impactOccurred] = useHapticFeedback();
  const showPopup = useShowPopup();
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isTariffPlansModalOpen, setIsTariffPlansModalOpen] = useState(false);
  const [isEarnMoneyWithUsModalOpen, setIsEarnMoneyWithUsModalOpen] =
    useState(false);
  const [hasRights, setHasRights] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, InitData] = useInitData();

  const BASE_URL = process.env.REACT_APP_API_URL;
  const usersApiPath = `${BASE_URL}/api/users`;

  window.Telegram.WebApp.disableClosingConfirmation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(usersApiPath + "/check_rights", {
          init_data: InitData,
        });
        setHasRights(response.data);
      } catch (error) {
        console.error(error);
        showPopup({
          message: "❗️ Что-то пошло не так, обратитесь в поддержку.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [InitData, showPopup, usersApiPath]);

  return (
    <>
      {isLoading && <Loader />}
      <div className={s.container}>
        <div className={s.buttons_group}>
          <button
            className={s.button}
            onClick={() => {
              impactOccurred("light");
              setIsTariffPlansModalOpen(true);
            }}
          >
            💰 Тарифы
          </button>
          <button
            className={s.button}
            onClick={() => {
              impactOccurred("light");
              setIsEarnMoneyWithUsModalOpen(true);
            }}
          >
            📊 Зарабатывай с нами
          </button>
          <Link
            to="/personal_account"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            🪪 Личный кабинет
          </Link>
          {hasRights && (
            <Link
              to="/moderation"
              className={s.button}
              onClick={() => impactOccurred("light")}
            >
              🔰 Модерация
            </Link>
          )}
          <Link
            to="/main_ad_category/create"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            📝 Создать объявление
          </Link>
          <Link
            to="/input_search_area"
            className={s.button}
            onClick={() => impactOccurred("light")}
          >
            📖 Посмотреть объявления
          </Link>
          <button
            className={s.button}
            onClick={() => {
              impactOccurred("light");
              setIsSupportModalOpen(true);
            }}
          >
            👤 Помощь
          </button>
        </div>
      </div>
      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
      />
      <TariffPlansModal
        isOpen={isTariffPlansModalOpen}
        onClose={() => setIsTariffPlansModalOpen(false)}
      />
      <EarnMoneyWithUs
        isOpen={isEarnMoneyWithUsModalOpen}
        onClose={() => setIsEarnMoneyWithUsModalOpen(false)}
      />
    </>
  );
};

export default HomePage;
